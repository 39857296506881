/* This example requires Tailwind CSS v2.0+ */
import { Menu, Popover, Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  ChevronDownIcon,
  CogIcon,
  InformationCircleIcon,
  LightBulbIcon,
  MailIcon,
  MenuIcon,
  PresentationChartLineIcon,
  ShieldCheckIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Fragment, useState } from 'react';

import { MobileNavigation } from '@/components/Pages/Docs/MobileNavigation';
import { Search } from '@/components/Pages/Docs/Search';
import { MobileNavigation as RoadmapMobileNavigation } from '@/components/Pages/Roadmap/MobileNavigation';
import logoSquare from '@/images/youtalky_com_logo_square.png';
import clsx from 'clsx';
import { signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SearchBar from './common/SearchBar';
import Skeleton from './common/Skeletons/Skeleton';
import { PauseIcon } from './common/Icons';

const meanuEntries = {
  important: [
    { name: 'Poradniki', href: '/docs', icon: LightBulbIcon },
    { name: 'O nas', href: '/about', icon: InformationCircleIcon },
    { name: 'Kontakt', href: '/contact', icon: MailIcon },
  ],
  legal: [
    { name: 'Polityka Prywatności', href: '/privacy-policy', icon: ShieldCheckIcon },
    { name: 'Zasady Użytkowania', href: '/terms-of-use', icon: AcademicCapIcon },
    { name: 'Kamienie Milowe', href: '/roadmap', icon: PresentationChartLineIcon },
  ],
};

export default function Header({ navigation, query, setQuery }) {
  let router = useRouter();
  let isDocsPage = router.pathname.includes('docs');
  let isRoadmapPage = router.pathname.includes('roadmap');

  const { data: session } = useSession();
  const [isLoaded, setIsLoaded] = useState(false);
  const [blogPosts, setPosts] = useState([]);

  const handleSignout = (e) => {
    e.preventDefault();
    signOut();
  };
  const onSubMenuOpen = async () => {
    // Load posts
    let data = await fetch(`${process.env.NEXT_PUBLIC_DJANGO_URL}/api/blog/posts/`);
    data = await data.json();
    setIsLoaded(true);
    setPosts(data.results.slice(0, 2));
  };

  return (
    <Popover className="fixed z-30 w-screen bg-white">

      <div className="shadow pointer-events-none absolute inset-0 z-30" aria-hidden="true" />
      <div className="z-18 relative ">
        <div className="shadow-bottom-sm sm:shadow-bottom mx-auto flex h-20 items-center justify-between px-3 py-4 sm:px-6 sm:py-5 md:justify-start md:space-x-10 lg:px-8">
          <div>
            <Link href="/" shallow>
              <span className="sr-only">Workflow</span>
              <Image
                className="aspect-square h-11 w-11"
                src={logoSquare}
                alt="YouTalky.com logo"
                width={40}
                height={40}
              />
            </Link>
          </div>
          <div id="search-mobile" className="visible mx-3 w-4/5 md:hidden">
            {isDocsPage && <MobileNavigation navigation={navigation} />}
            {isRoadmapPage && <RoadmapMobileNavigation navigation={navigation} />}

            {isDocsPage || isRoadmapPage ? null : (
              <SearchBar query={query} setQuery={setQuery}></SearchBar>
            )}
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <div>
                <Link
                  href="/docs"
                  className="ml-6 text-base font-bold text-yellow-500 hover:text-yellow-600"
                >
                  Co i Jak?
                </Link>
              </div>
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onClick={() => onSubMenuOpen()}
                      className={clsx(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                      )}
                    >
                      <span>Więcej</span>
                      <ChevronDownIcon
                        className={clsx(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                    >
                      <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform shadow-lg md:block">
                        <div className="absolute inset-0 flex">
                          <div className="w-1/2 bg-white" />
                          <div className="w-1/2 bg-gray-50" />
                        </div>
                        <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                          <nav className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                            <div>
                              <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                Ważne detale
                              </h3>
                              <ul role="list" className="mt-5 space-y-6">
                                {meanuEntries.important.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <Link
                                      href={item.href}
                                      className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                                    >
                                      <item.icon
                                        className="h-6 w-6 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-4">{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                Inne konieczności
                              </h3>
                              <ul role="list" className="mt-5 space-y-6">
                                {meanuEntries.legal.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <Link
                                      href={item.href}
                                      className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                                    >
                                      <item.icon
                                        className="h-6 w-6 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-4">{item.name}</span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                              {/* <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                <span className="sr-only">Close menu</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </Popover.Button> */}
                            </div>
                          </nav>
                          <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                            <div>
                              <h3 className="text-sm font-medium uppercase tracking-wide text-gray-500">
                                Z bloga
                              </h3>
                              {isLoaded ? (
                                <ul role="list" className="mt-6 space-y-6">
                                  {blogPosts.map((post) => (
                                    <li key={post.id} className="flow-root">
                                      <Link
                                        href={`/blog/${post.slug}`}
                                        className="-m-3 flex rounded-lg p-3 hover:bg-gray-100"
                                      >
                                        <div className="hidden flex-shrink-0 sm:block">
                                          <Image
                                            className="h-20 w-32 rounded-md object-cover"
                                            src={post.thumbnailMedium}
                                            alt="Post picture"
                                            width={100}
                                            height={100}
                                          />
                                        </div>
                                        <div className="w-0 flex-1 sm:ml-8">
                                          <h4 className="truncate text-base font-medium text-gray-900">
                                            {post.title}
                                          </h4>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {post.overview}
                                          </p>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <Skeleton></Skeleton>
                              )}
                            </div>
                            <div className="mt-6 text-sm font-medium">
                              <Link href="/blog" className="text-blue-600 hover:text-blue-500">
                                {' '}
                                Zobacz wszystkie posty <span aria-hidden="true">&rarr;</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>

            <div id="search-desktop" className="w-1/2 text-center">
              {isDocsPage || isRoadmapPage ? (
                <Search></Search>
              ) : (
                <SearchBar query={query} setQuery={setQuery}></SearchBar>
              )}
            </div>
            <div className="flex items-center md:ml-12">
              {!session ? (
                <>
                  {/* <Link
                    href="/auth/login"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    Zaloguj
                  </Link>
                  <Link
                    href="/auth/register"
                    className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                  >
                    Zarejestruj
                  </Link> */}
                </>
              ) : (
                <>
                  {/* Profile dropdown */}
                  <Link
                    key={'Bibliodesk'}
                    href={'/dashboard'}
                    className="text-md mr-2 block rounded-lg border-2 border-white bg-teal-500 py-2 px-4 font-bold tracking-normal text-white hover:border-2 hover:border-teal-700 hover:bg-teal-600"
                  >
                    {'Bibliodesk'}
                  </Link>
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full text-sm text-white focus:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-900">
                        <span className="sr-only">Open user menu</span>
                        <Image
                          className="h-10 w-10 rounded-full"
                          src={session.user.image}
                          alt="User Image Avatar"
                          width={40}
                          height={40}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <Link
                            href={`/u/${session.user.id}`}
                            className={clsx(
                              'block py-2 px-4 text-sm text-gray-700 hover:bg-teal-600 hover:text-white'
                            )}
                          >
                            Twój Profil
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            href={`/u/${session.user.id}/settings`}
                            className={clsx(
                              'block py-2 px-4 text-sm text-gray-700 hover:bg-teal-600 hover:text-white'
                            )}
                          >
                            Ustawienia
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            href={'/'}
                            className={clsx(
                              'block py-2 px-4 text-sm text-rose-500 text-gray-700 hover:bg-rose-600 hover:text-white'
                            )}
                          >
                            <div onClick={handleSignout}>Wyloguj</div>
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 w-screen origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <Link href={'/'} shallow>
                    <Image
                      className="h-8 w-auto"
                      src={logoSquare}
                      alt="YouTalky.com"
                      width={30}
                      height={30}
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>

              {/* Mobile Menu  */}
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                    {session ? (
                      <>
                        <div className="mt-4 flex flex-col text-center text-lg font-bold">
                          <Link
                            href="/dashboard"
                            className="my-3 text-teal-600 hover:text-teal-500"
                          >
                            Bibliodesk
                          </Link>
                        </div>
                        <div className="mt-4 flex flex-col text-center text-lg font-bold">
                          <Link href="/docs" className="my-3 text-blue-600 hover:text-blue-500">
                            Jak korzystać?
                          </Link>
                        </div>

                        <div className="border-t border-blue-800 pt-4 pb-3">
                          <div className="flex items-center px-4">
                            <div className="flex-shrink-0">
                              <Image
                                className="h-12 w-12 rounded-full"
                                src={session.user.image}
                                alt="user Image"
                                width={45}
                                height={45}
                              />
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium text-gray-800">
                                {session.user.name}
                              </div>
                              <div className="text-sm font-medium text-gray-800">
                                {session.user.email}
                              </div>
                            </div>
                            <Link
                              href={`/u/${session.user.id}/settings`}
                              className="ml-auto flex-shrink-0 rounded-full p-1 text-gray-800 hover:bg-teal-800 hover:text-white focus:bg-teal-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-teal-900"
                            >
                              <span className="sr-only">View notifications</span>
                              <CogIcon className="h-6 w-6" aria-hidden="true"></CogIcon>
                            </Link>
                          </div>
                          <div className="mt-3 px-2">
                            <Link
                              href={`/u/${session.user.id}`}
                              className="block rounded-md py-2 px-3 text-base font-medium text-gray-800 hover:bg-teal-500 hover:text-white"
                            >
                              Twój Profil
                            </Link>

                            <Link
                              href={'/'}
                              className={clsx(
                                'block rounded-md py-2 px-3 text-base font-medium text-rose-500 text-red-800 hover:bg-red-400 hover:text-white'
                              )}
                            >
                              <div onClick={handleSignout}>Wyloguj</div>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-4 flex flex-col text-center text-lg font-bold">
                          <Link href="/about" className="my-3 text-teal-600 hover:text-teal-500">
                            O nas
                          </Link>
                          <Link href="/blog" className="my-3 text-teal-600 hover:text-teal-500">
                            Blog
                          </Link>
                          <Link href="/kody" className="my-3 text-yellow-600 hover:text-yellow-500">
                            Kody
                          </Link>
                        </div>

                        {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                          <Link
                            href="/auth/login"
                            className="text-base font-medium text-gray-500 hover:text-gray-900"
                          >
                            Zaloguj
                          </Link>
                          <Link
                            href="/auth/register"
                            className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                          >
                            Zarejestruj
                          </Link>
                        </p> */}
                      </>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
